import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import CareersHero from '../components/organisms/CareersHero';
import CommunityFeatureSection from '../components/organisms/CommunityFeatureSection';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';
import RightAroundTheCornerSection from '../components/organisms/RightAroundTheCornerSection';

const Careers = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <CareersHero image={page.careers.hero.image} />
        <CommunityFeatureSection
          {...page.careers.careersfeature}
          id="careers-feature"
        />
        <section className="relative">
          <iframe
            className="w-full aspect-video"
            src={`https://www.youtube.com/embed/${page.careers.youtubeVideoId}`}
            frameBorder="0"
            title="The Salty Donut Careers Video"
          />
        </section>
        <RightAroundTheCornerSection
          {...page.careers?.rightAroundTheCornerSection}
        />
        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query CareersPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      careers {
        hero {
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1440, placeholder: BLURRED)
              }
            }
          }
        }
        careersfeature {
          heading
          body
          buttonLabel
          buttonUrl
        }
        youtubeVideoId
        rightAroundTheCornerSection {
          image {
            altText
            localFile {
              url
            }
          }
          title
          body
          button {
            label
            url
          }
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Careers;
