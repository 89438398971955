import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useScrollTo } from '../../hooks/use-scroll-to';
import RoundButtonBg from '../../images/round-button-bg.svg';
import WaveWhite from '../../images/wave-white.png';
import ArrowRightIcon from '../atoms/ArrowRightIcon';
import Image from '../atoms/Image';

interface CareersHeroProps {
  image: {
    altText: string;
    localFile: any;
  };
}

const CareersHero = ({ image }: CareersHeroProps) => {
  const { scrollTo } = useScrollTo();
  const { scrollY } = useViewportScroll();
  const y = useTransform(scrollY, [0, 675], [0, 100], { clamp: false });
  const y1 = useTransform(scrollY, [0, 675], [0, 300], { clamp: false });
  const opacity = useTransform(scrollY, [0, 675], [1, 0]);
  const x = useTransform(scrollY, [0, 5000], [0, -1000]);

  const handleScrollToContent = () => {
    scrollTo('careers-feature');
  };

  return (
    <section className="relative mt-[92px] overflow-hidden">
      <motion.div className="absolute inset-0 z-0" style={{ y }}>
        <Image
          image={getImage(image?.localFile)}
          alt={image?.altText}
          className="w-full h-auto"
        />
      </motion.div>
      <Image
        image={getImage(image?.localFile)}
        alt={image?.altText}
        className="w-full h-auto opacity-0"
      />

      <div className="absolute left-0 right-0 hidden mt-8 bottom-16 lg:bottom-24 sm:block">
        <motion.button
          type="button"
          className="flex items-center justify-center w-12 h-12 mx-auto bg-center bg-no-repeat bg-contain rounded-full focus:outline-none"
          style={{ backgroundImage: `url(${RoundButtonBg})`, y: y1, opacity }}
          aria-label="Scroll to content"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={handleScrollToContent}
        >
          <ArrowRightIcon className="transform rotate-90 animate-slide1" />
        </motion.button>
      </div>
      <div className="absolute left-0 right-0 h-8 overflow-hidden -bottom-px">
        <motion.div
          className="w-[10000px] h-full bg-repeat-x"
          style={{
            backgroundImage: `url(${WaveWhite})`,
            backgroundSize: '20% 100%',
            x: x,
          }}
        />
      </div>
    </section>
  );
};

export default CareersHero;
